/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { toast } from "react-toastify";
import { IToggleModalTypes } from "./types";
import {
  IAddInquiryTypes,
  IInquirySupplierListRes,
} from "../redux/modules/distribution";
import { IFabricationTypes } from "../redux/modules/fabrication/types";
import { setShowModalPopup } from "../redux/actions/action";
import { downloadFile1, getData } from "../api/rest/fetchData";
import { App_url } from "./constants/static";
import { AddIProjectType } from "../redux/modules/projectManagement";
import { CombinedDeliveredList } from "../redux/modules/distribution/types";

export const formContaint = (modal: string, name: string, submit: string) => {
  return {
    title: `${modal === "edit" ? "Edit" : modal} ${name}`,
    submit: submit ? submit : "Submit",
    // close: "Cancel",
  };
};

export const historyContaint = (modal: string, name: string) => {
  return {
    title: `${modal === "history" ? "History" : modal} ${name}`,
    //  submit: submit?submit:"Submit",
    //  close: "Cancel",
  };
};

export function UUID4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
  });
}

export const toggleModal = (
  state: IToggleModalTypes,
  setShowModal: React.Dispatch<React.SetStateAction<IToggleModalTypes>>
) => {
  setShowModal(state);
};

export const warnContaint = (
  title: string,
  description: string,
  submit?: string,
  close?: string
) => {
  return {
    title: title,
    description: description,
    submit: submit ? submit : "Submit",
    close: close ? close : "Close",
  };
};

export const formatDate = (dateString: string): string => {


  const date = new Date(dateString);

  // Extract date components in UTC to avoid timezone issues
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth is 0-based
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${month}/${day}/${year}`;
};


export const formatCreatedDate = (dateString: string): string => {
  const date = new Date(dateString);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // getMonth() returns 0-based month
  const year = date.getFullYear();

  // Format to DD/MM/YYYY
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedMonth}/${formattedDay}/${year}`;
};

export function formatDate2(dateString: string): string {
  console.log("formatDate2::", dateString);

  const date = new Date(dateString);

  // Extract date components in UTC to avoid timezone issues
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth is 0-based
  const day = String(date.getUTCDate()).padStart(2, "0");

  console.log(`${year}-${month}-${day}`);

  return `${year}-${month}-${day}`;
}

export function formatDateMMDDYY(dateString: string): string {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${month}-${day}-${year}`;
}

export function getWeekday(dateString) {
  const date = new Date(dateString);
  const options: any = { weekday: "long" };
  return date.toLocaleDateString("en-US", options);
}

export const transformData = (data: any) => {
  return data?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));
};

export const roletransformData = (data: any) => {
  return data?.map((item: any) => ({
    value: item.id,
    label: item.role_name,
  }));
};

export function convertDateToMonth(dateStr) {
  const date = new Date(dateStr);
  const options: any = { year: "numeric", month: "short" };
  return date.toLocaleDateString("en-US", options);
}

export const countryData = (data: any) => {
  // if(data){
  //   return data?.map((item:any) => ({
  //     value: item.country,
  //     label: item.country,
  //   }));
  // }else{
  return [];
  // }
};
export const renderPhoneValidate = (value, textError) => {
  if (
    value.input_value &&
    value.format &&
    value.input_value.length === value.format.length
  ) {
    return true;
  } else {
    return textError || "Invalid valid Contact Number";
  }
};
export const YearsAgo = (years) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);
  return date;
};

export const CommonResponse = (data) => {
  if (data?.errors?.length > 0) {
    data?.errors?.map((item, index) => {
      if (item?.message) {
        toast.error(item?.message);
      }
    });
  } else {
    toast.error(data?.msg);
  }
};

export const getPhone = (phone: string, phoneCode: string) => {
  if (phone.startsWith(phoneCode)) {
    // Remove the phone code from the start of the phone number
    return phone.substring(phoneCode.length);
  }
};

export const getEmailTemplatePayload = (formData) => {
  const payload: any = {
    email_from: "",
    email_to: "",
    email_subject: "",
    email_body: "",
  };
  if (formData?.email_from) {
    payload.email_from = formData?.email_from;
  }
  if (formData?.email_to) {
    payload.email_to = formData?.email_to;
  }
  if (formData?.email_subject) {
    payload.email_subject = formData?.email_subject;
  }
  if (formData?.email_body) {
    payload.email_body = formData?.email_body;
  }
  if (formData?.email_tc) {
    payload.email_tc = formData?.email_tc;
  }
  if (formData?.email_notes) {
    payload.email_notes = formData?.email_notes;
  }
  return payload;
};
export const getSenderSetupPayload = (formData) => {
  const payload = {
    // account_email_host: "",
    // account_email_email: "",
    // account_email_password: "",
    // account_email_port: "",
    // account_email_type: "",
    quick_book_id: "",
    quick_book_secret: "",
    padlet_key: "",
    distribution_board_id: "",
    quick_book_env: "",
    fabrication_board_id: "",
    stock_board_id: "",
  };
  // if(formData?.account_email_host){
  //   payload.account_email_host = formData?.account_email_host
  // }
  if (formData?.fabrication_board_id) {
    payload.fabrication_board_id = formData?.fabrication_board_id;
  }
  if (formData?.stock_board_id) {
    payload.stock_board_id = formData?.stock_board_id;
  }
  // if(formData?.account_email_email){
  //   payload.account_email_email = formData?.account_email_email
  // }
  // if(formData?.account_email_password){
  //   payload.account_email_password = formData?.account_email_password
  // }
  // if(formData?.account_email_port){
  //   payload.account_email_port = formData?.account_email_port
  // }
  // if(formData?.account_email_type){
  //   payload.account_email_type = formData?.account_email_type
  // }
  if (formData?.quick_book_id) {
    payload.quick_book_id = formData?.quick_book_id;
  }
  if (formData?.quick_book_secret) {
    payload.quick_book_secret = formData?.quick_book_secret;
  }
  if (formData?.quick_book_env) {
    payload.quick_book_env = formData?.quick_book_env;
  }
  if (formData?.padlet_key) {
    payload.padlet_key = formData?.padlet_key;
  }
  if (formData?.distribution_board_id) {
    payload.distribution_board_id = formData?.distribution_board_id;
  }
  return payload;
};

export const getEmailSetupPayload = (formData) => {
  const payload = {
    account_email_host: "",
    account_email_email: "",
    account_email_password: "",
    account_email_port: "",
    account_email_type: "",
    quick_book_id: "",
    quick_book_secret: "",
    padlet_key: "",
    distribution_board_id: "",
    quick_book_env: "",
  };
  if (formData?.account_email_host) {
    payload.account_email_host = formData?.account_email_host;
  }
  if (formData?.account_email_email) {
    payload.account_email_email = formData?.account_email_email;
  }
  if (formData?.account_email_password) {
    payload.account_email_password = formData?.account_email_password;
  }
  if (formData?.account_email_port) {
    payload.account_email_port = formData?.account_email_port;
  }
  if (formData?.account_email_type) {
    payload.account_email_type = formData?.account_email_type;
  }
  if (formData?.quick_book_id) {
    payload.quick_book_id = formData?.quick_book_id;
  }
  if (formData?.quick_book_secret) {
    payload.quick_book_secret = formData?.quick_book_secret;
  }
  if (formData?.quick_book_env) {
    payload.quick_book_env = formData?.quick_book_env;
  }
  if (formData?.padlet_key) {
    payload.padlet_key = formData?.padlet_key;
  }
  if (formData?.distribution_board_id) {
    payload.distribution_board_id = formData?.distribution_board_id;
  }
  return payload;
};
export const getPayloadCustomer = (formData) => {
  const payload = {
    address: "",
    city: "",
    email: "",
    name: "",
    state: "",
    state_id: "",
    city_id: "",
    zip_code: "",
    phone: "",
    phone_code: "",
    contact_person: [],
    tax_exempt: false,
  };
  if (formData?.tax_exempt != undefined) {
    payload.tax_exempt = formData?.tax_exempt;
  }
  if (formData?.address) {
    payload.address = formData?.address;
  }
  if (formData?.tax_exempt) {
    payload.tax_exempt = formData?.tax_exempt;
  }
  if (formData?.city) {
    payload.city = formData?.city;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.name) {
    payload.name = formData?.name;
  }
  if (formData?.state) {
    payload.state = formData?.state;
  }
  if (formData?.state_id) {
    payload.state_id = formData?.state_id;
  }
  if (formData?.city_id) {
    payload.city_id = formData?.city_id;
  }
  if (formData?.zip_code) {
    payload.zip_code = formData?.zip_code;
  }
  if (formData?.phoneNumber) {
    payload.phone = formData?.phoneNumber?.phone.slice(
      formData?.phoneNumber?.phone_code?.length
    );
    payload.phone_code = formData?.phoneNumber?.phone_code;
  }
  if (formData?.contact_person?.length > 0) {
    formData?.contact_person?.map((formData) => {
      const payloadData = {
        name: "",
        email: "",
        phone: "",
        phone_code: "",
      };
      if (formData?.email) {
        payloadData.email = formData?.email;
      }
      if (formData?.name) {
        payloadData.name = formData?.name;
      }
      if (formData?.phoneNumber) {
        payloadData.phone = formData?.phoneNumber?.phone.slice(
          formData?.phoneNumber?.phone_code?.length
        );
        payloadData.phone_code = formData?.phoneNumber?.phone_code;
      }
      payload.contact_person.push(payloadData);
    });
  }
  return payload;
};

export const getDistributionPayload = (formData, param?: any) => {
  const payload:any = {
    payment_terms_id:'',
    term_condition: "",
    tax_amount: 0,
    tax_percentage: 0,
    shipping_amount: 0,
    tax: "",
    shipping_pincode: "",
    billing_pincode: "",
    supplier_id: "",
    po_number: "",
    customer_name: "",
    project_name: "",
    email: "",
    fob: "",
    via: "",
    contact_name: "",
    customer_id: "",
    inquiry_sale_products: [],
    shipping_address: "",
    city: "",
    state: "",
    country: "",
    billing_address: "",
    payment_terms: "",
    rdd: "",
    zip_code: "",
    ship_to_company_name: "",
    bill_to_company_name: "",
    status: "",
  };
  if (formData?.supplier_id) {
    payload.supplier_id = formData?.supplier_id;
  }
  if (formData?.shipping_pincode) {
    payload.shipping_pincode = formData?.shipping_pincode;
  }
  if (formData?.bill_to_company_name) {
    payload.bill_to_company_name = formData?.bill_to_company_name;
  }
  if (formData?.ship_to_company_name) {
    payload.ship_to_company_name = formData?.ship_to_company_name;
  }
  if (formData?.billing_pincode) {
    payload.billing_pincode = formData?.billing_pincode;
  }
  if (param?.supplier_id) {
    payload.id = param?.supplier_id;
  }
  if (formData?.po_number) {
    payload.po_number = formData?.po_number;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.fob) {
    payload.fob = formData?.fob;
  }
  if (formData?.via) {
    payload.via = formData?.via;
  }
  if (formData?.contact_name) {
    payload.contact_name = formData?.contact_name;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  // if (formData?.shipping_address) {
  //   payload.shipping_address = formData?.shipping_address;
  // }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
    if (formData?.billingAddress?.city) {
      payload.city = formData?.billingAddress?.city;
    }
    if (formData?.billingAddress?.country) {
      payload.country = formData?.billingAddress?.country;
    }
    if (formData?.billingAddress?.state) {
      payload.state = formData?.billingAddress?.state;
    }
    if (formData?.billingAddress?.postal_code) {
      payload.zip_code = formData?.billingAddress?.postal_code;
      payload.billing_pincode = formData?.billingAddress?.postal_code;
    }
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.shippingAddress) {
    payload.shipping_address = formData?.shippingAddress?.address;
    // payload.city = formData?.shippingAddress?.city;
    // payload.country = formData?.shippingAddress?.country;
    // payload.state = formData?.shippingAddress?.state;
    // payload.zip_code = formData?.shippingAddress?.postal_code;
    if (formData?.shippingAddress?.postal_code) {
      payload.shipping_pincode = formData?.shippingAddress?.postal_code;
    }
  }
  if (formData?.payment_terms) {
    payload.payment_terms = formData?.payment_terms;
  }
  if (formData?.payment_terms_id) {
    payload.payment_terms_id = formData?.payment_terms_id;
  }
  if (formData?.tax) {
    payload.tax = formData?.tax;
  }
  if (formData?.term_condition) {
    payload.term_condition = formData?.term_condition;
  }
  if (formData?.rdd) {
    payload.rdd = formatDate2(formData?.rdd);
  }
  if (formData?.tax_percentage) {
    payload.tax_percentage = Number(formData?.tax_percentage);
  }

  if (formData?.shipping_amount) {
    payload.shipping_amount = Number(formData?.shipping_amount);
  }
  if (formData?.inquiry_sale_products) {
    payload.inquiry_sale_products = formData?.inquiry_sale_products?.map(
      (item, index) => {
        const payloadData:any = {
          product_name: item?.product_name,
          product_qty: parseFloat(item?.product_qty),
          product_unit_id: item?.product_unit_id,
          product_unit: item?.product_unit,
          product_buy_price: parseFloat(item?.product_buy_price),
          seller_price: parseFloat(item?.seller_price),
          product_margin: parseFloat(item?.product_margin),
          total_price: Number(item?.total_price),
        };
        if(item.id){
          payloadData.id = item?.id;
        }
        return payloadData;
      }
    );
  }
  // if (formData?.tax_amount) {
  // let valueTaxAmount = tax_amount + Number(formData?.shipping_amount);

  // const taxAmount = formatIfFloat((valueTaxAmount * formData?.tax_percentage) / 100);
  // payload.tax_amount = taxAmount;
  // }
  if (formData?.tax_amount != "") {
    payload.tax_amount = Number(formData?.tax_amount);
  }
  return payload;
};
export const formatIfFloat = (value) => {
  if (typeof value === "number") {
    if (value % 1 !== 0) {
      return parseFloat(value.toFixed(4));
    }
    return value;
  }
  return value;
};
export function calculateTotalPrice(
  buyPrice,
  quantity,
  margin = null,
  sellerPrice = null
) {
  let total, calculatedMargin, calculatedSellerPrice;

  if (sellerPrice !== null) {
    total = quantity * sellerPrice;
    calculatedMargin = (sellerPrice / buyPrice - 1) * 100;
    return {
      total,
      margin: calculatedMargin,
    };
  } else if (margin !== null) {
    calculatedSellerPrice = buyPrice * (1 + margin / 100);
    total = quantity * calculatedSellerPrice;
    return {
      total,
      sellerPrice: calculatedSellerPrice,
    };
  } else {
    throw new Error("Either margin or seller price must be provided.");
  }
}
export const getCalculation = (
  buyPrice,
  quantity,
  margin = null,
  sellerPrice = null
) => {
  let result: any = {};
  try {
    if (isNaN(buyPrice) || isNaN(quantity)) {
      throw new Error("Buying Price and Quantity are required.");
    }

    if (!isNaN(sellerPrice)) {
      result = calculateTotalPrice(buyPrice, quantity, null, sellerPrice);
    } else if (!isNaN(margin)) {
      result = calculateTotalPrice(buyPrice, quantity, margin);
    } else {
      throw new Error("Either margin or seller price must be provided.");
    }

    // document.getElementById('result').innerHTML = `
    //     <strong>Total Cost:</strong> $${result.total.toFixed(2)}<br>
    //     ${result.margin !== undefined ? `<strong>Margin:</strong> ${result.margin.toFixed(2)}%<br>` : ''}
    //     ${result.sellerPrice !== undefined ? `<strong>Seller Price:</strong> $${result.sellerPrice.toFixed(2)}<br>` : ''}
    // `;
    return result;
  } catch (error) {
    document.getElementById(
      "result"
    ).innerHTML = `<strong>Error:</strong> ${error.message}`;
  }
};
export function calculateTotal(
  buyPrice,
  quantity,
  product_margin = null,
  seller_price = null
) {
  let total, calculatedMargin, calculatedSellerPrice;
  if (seller_price !== null && seller_price !== "" && seller_price > buyPrice) {
    total = quantity * seller_price;
    if (buyPrice !== 0) {
      calculatedMargin = formatIfFloat((seller_price / buyPrice - 1) * 100);
    } else {
      calculatedMargin = 0; // or some appropriate value or error handling
    }
    if (product_margin !== null && product_margin !== "") {
      calculatedSellerPrice = formatIfFloat(
        buyPrice * (1 + product_margin / 100)
      );
      total = quantity * calculatedSellerPrice;
      return {
        total: formatIfFloat(total),
        seller_price: calculatedSellerPrice,
        product_margin: calculatedMargin,
      };
    } else {
      return {
        total,
        product_margin: calculatedMargin,
      };
    }
  } else if (product_margin !== null && product_margin !== "" && buyPrice) {
    calculatedSellerPrice = formatIfFloat(
      buyPrice * (1 + product_margin / 100)
    );
    total = quantity * calculatedSellerPrice;
    return {
      total: formatIfFloat(total),
      seller_price: calculatedSellerPrice,
    };
  } else {
    calculatedSellerPrice = formatIfFloat(
      buyPrice * (1 + product_margin / 100)
    );
    total = quantity * calculatedSellerPrice;
    return {
      total: total ? formatIfFloat(total) : "",
      seller_price: calculatedSellerPrice,
    };
  }
}

export function getLowestPricedItem(
  list: IInquirySupplierListRes
): IAddInquiryTypes | undefined {
  if (!list.items || list.items.length === 0) {
    return undefined;
  }

  // Filter out items with undefined or non-numeric prices
  const itemsWithPrices = list.items.filter(
    (item) => item.price && !isNaN(Number(item.price))
  );

  // Find the item with the lowest price
  let lowestPricedItem = itemsWithPrices[0];

  for (const item of itemsWithPrices) {
    if (Number(item.price) < Number(lowestPricedItem.price)) {
      lowestPricedItem = item;
    }
  }

  return lowestPricedItem;
}
//projects payloads object
export const getPayloadProject = (formData) => {
  const payload = {
    customer_name: "",
    project_number: "",
    project_name: "",
    project_address: "",
    //specific details
    project_type: "",
    project_scope: "",
    estimated_tonnage: "",
    scope_of_work: "",
    date_of_received: "",
    date_of_completion: "",
    //Foreman Details
    foreman_name: "",
    foreman_email: "",
    foreman_contact: "",
    //Coordinator & Contractor Details
    coordinator_name: "",
    coordinator_email: "",
    coordinator_contact: "",
    submission_email: "",
    general_contractor: "",
    concrete_contractor: "",
    //other details
    architect: "",
    engineer: "",
    items_of_billing: "",
    rate: "",
    rate_of_escalation: "",
  };
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.project_number) {
    payload.project_number = formData?.project_number;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.project_address) {
    payload.project_address = formData?.project_address;
  }
  if (formData?.project_type) {
    payload.project_type = formData?.project_type;
  }
  if (formData?.project_scope) {
    payload.project_scope = formData?.project_scope;
  }
  if (formData?.estimated_tonnage) {
    payload.estimated_tonnage = formData?.estimated_tonnage;
  }
  if (formData?.scope_of_work) {
    payload.scope_of_work = formData?.scope_of_work;
  }
  if (formData?.date_of_received) {
    payload.date_of_received = formData?.date_of_received;
  }
  if (formData?.date_of_completion) {
    payload.date_of_completion = formData?.date_of_completion;
  }
  if (formData?.foreman_name) {
    payload.foreman_name = formData?.foreman_name;
  }
  if (formData?.foreman_email) {
    payload.foreman_email = formData?.foreman_email;
  }
  if (formData?.coordinator_name) {
    payload.coordinator_name = formData?.coordinator_name;
  }
  if (formData?.coordinator_email) {
    payload.coordinator_email = formData?.coordinator_email;
  }
  if (formData?.coordinator_contact) {
    payload.coordinator_contact = formData?.coordinator_contact;
  }
  if (formData?.submission_email) {
    payload.submission_email = formData?.submission_email;
  }
  if (formData?.general_contractor) {
    payload.general_contractor = formData?.general_contractor;
  }
  if (formData?.concrete_contractor) {
    payload.concrete_contractor = formData?.concrete_contractor;
  }
  if (formData?.architect) {
    payload.architect = formData?.architect;
  }
  if (formData?.engineer) {
    payload.engineer = formData?.engineer;
  }
  if (formData?.items_of_billing) {
    payload.items_of_billing = formData?.items_of_billing;
  }
  if (formData?.rate) {
    payload.rate = formData?.rate;
  }
  if (formData?.rate_of_escalation) {
    payload.rate_of_escalation = formData?.rate_of_escalation;
  }
  return payload;
};

export const checkPermission = (user_data: any, data: any) => {
  const api_permissions = user_data?.user?.api_permissions?.split(",");
  if (
    user_data?.user?.role === "admin" ||
    api_permissions?.includes(`${data}`)
  ) {
    return true;
  } else {
    return false;
  }
};

export const convertLabelAdValue = (list) => {
  return list?.map((item: any, index) => {
    return { label: item?.label, value: item?.label };
  });
};

export const openPreview = (data: string, dispatch: any) => {
  const binaryString = atob(data);
  const bytes = new Uint8Array(binaryString?.length);

  for (let i = 0; i < binaryString?.length; i++) {
    bytes[i] = binaryString?.charCodeAt(i);
  }

  // Create a Blob from the binary data
  const blob = new Blob([bytes], { type: "application/pdf" });

  // Generate a download URL
  const url = URL.createObjectURL(blob);
  dispatch(
    setShowModalPopup({
      show: "PREVIEW",
      data: { url: url },
    })
  );
};

export const getFabricationPayload = (formData: IFabricationTypes) => {
  const payload: any = {
    product_list: [],
    project_id: "",
    project_name: "",
    customer_id: "",
    customer_name: "",
    shipping_address: "",
    billing_address: "",
    edd: "",
    note: "",
    id: "",
    tax: "",
    tax_percentage: 0,
    shipping_amount: 0,
    tax_amount: 0,
    payment_terms_id:"",
    payment_terms:"",
  };
  if (formData?.product_list?.length > 0) {
    formData?.product_list?.map((item) => {
      const payloadData = {
        product_id: "",
        product_name: "",
        specification_id: "",
        specification: "",
        grade_id: "",
        grade: "",
        coating_id: "",
        coating: "",
        supplier_type_id: "",
        supplier_type: "",
        supplier_id: "",
        supplier: "",
        id: "",
        product_qty: 0,
        total_price: 0,
        length_name: "",
        length_id: "",
        seller_price: 0,
        location_name:"",
        location_id:""
      };
      if (item?.id) {
        payloadData.id = item?.id;
      } else {
        delete payloadData?.id;
      }
      if (item?.product_name) {
        payloadData.product_name = item?.product_name;
      }
      if (item?.product_id) {
        payloadData.product_id = item?.product_id;
      }
      if (item?.specification_id) {
        payloadData.specification_id = item?.specification_id;
      }
      if (item?.specification) {
        payloadData.specification = item?.specification;
      }
      if (item?.grade_id) {
        payloadData.grade_id = item?.grade_id;
      }
      if (item?.product_qty) {
        payloadData.product_qty = Number(item?.product_qty);
      }
      if (item?.total_price) {
        payloadData.total_price = Number(item?.total_price);
      }
      if (item?.seller_price) {
        payloadData.seller_price = Number(item?.seller_price);
      }
      if (item?.grade) {
        payloadData.grade = item?.grade;
      }
      if (item?.coating_id) {
        payloadData.coating_id = item?.coating_id;
      }
      if (item?.coating) {
        payloadData.coating = item?.coating;
      }
      if (item?.supplier_type_id) {
        payloadData.supplier_type_id = item?.supplier_type_id;
      }
      if (item?.supplier_type) {
        payloadData.supplier_type = item?.supplier_type;
      }
      if (item?.supplier_id) {
        payloadData.supplier_id = item?.supplier_id;
      }
      if (item?.length_id) {
        payloadData.length_id = item?.length_id;
      }
      if (item?.length_name) {
        payloadData.length_name = item?.length_name;
      }
      if (item?.supplier) {
        payloadData.supplier = item?.supplier;
      }
      if (formData?.location_name) {
        payloadData.location_name = formData?.location_name;
      }
      if (formData?.location_id) {
        payloadData.location_id = formData?.location_id;
      }

      payload.product_list.push(payloadData);
    });
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.payment_terms) {
    payload.payment_terms = formData?.payment_terms;
  }
  if (formData?.payment_terms_id) {
    payload.payment_terms_id = formData?.payment_terms_id;
  }
  if (formData?.tax) {
    payload.tax = formData?.tax;
  }

  if (formData?.tax_amount) {
    payload.tax_amount = Number(formData?.tax_amount);
  }
  if (formData?.tax_percentage) {
    payload.tax_percentage = Number(formData?.tax_percentage);
  }
  if (formData?.shipping_amount) {
    payload.shipping_amount = Number(formData?.shipping_amount);
  }

  if (formData?.shippingAddress) {
    payload.shipping_address = formData?.shippingAddress?.address;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
  }
  if (formData?.id) {
    payload.id = formData?.id;
  } else {
    delete payload?.id;
  }

  if (formData?.project_id) {
    payload.project_id = formData?.project_id;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.edd) {
    payload.edd = formatDate2(formData?.edd);
  }
  if (formData?.note) {
    payload.note = formData?.note;
  }
  // if (formData?.status) {
  //   payload.status = formData?.status;
  // }
  return payload;
};
export const getInquiryPayload = (formData: IAddInquiryTypes) => {
  const payload: any = {
    customer_id: "",
    customer_name: "",
    project_name: "",
    shipping_address: "",
    billing_address: "",
    email: "",
    phone: "",
    phone_code: "",
    rdd: "",
    dor: "",
    // country_id:"",
    // state_id:"",
    // country:"",
    // state:"",
    // city:"",
    // zip_code:"",
    // address:"",
    term_condition: "",
    inquiry_products: [],
    id: "",
  };
  if (formData?.id) {
    payload.id = formData?.id;
  } else {
    delete payload?.id;
  }
  if (formData?.customer_id) {
    payload.customer_id = formData?.customer_id;
  }
  if (formData?.customer_name) {
    payload.customer_name = formData?.customer_name;
  }
  if (formData?.project_name) {
    payload.project_name = formData?.project_name;
  }
  if (formData?.billing_address) {
    payload.billing_address = formData?.billing_address;
  }
  if (formData?.billingAddress) {
    payload.billing_address = formData?.billingAddress?.address;
    if (formData?.billingAddress?.city) {
      payload.city = formData?.billingAddress?.city;
    }
    if (formData?.billingAddress?.country) {
      payload.country = formData?.billingAddress?.country;
    }
    if (formData?.billingAddress?.state) {
      payload.state = formData?.billingAddress?.state;
    }
    if (formData?.billingAddress?.postal_code) {
      payload.zip_code = formData?.billingAddress?.postal_code;
    }
  }
  if (formData?.shipping_address) {
    payload.shipping_address = formData?.shipping_address;
  }
  if (formData?.shippingAddress) {
    payload.shipping_address = formData?.shippingAddress?.address;
    // payload.city = formData?.shippingAddress?.city;
    // payload.country = formData?.shippingAddress?.country;
    // payload.state = formData?.shippingAddress?.state;
    // payload.zip_code = formData?.shippingAddress?.postal_code;
  }
  if (formData?.email) {
    payload.email = formData?.email;
  }
  if (formData?.phone_code) {
    payload.phone_code = formData?.phone_code;
  }
  if (formData?.phone) {
    payload.phone = formData?.phone;
  }
  if (formData?.dor) {
    payload.dor = formData?.dor;
  }
  if (formData?.rdd) {
    payload.rdd = formData?.rdd;
  }
  // if(formData?.country_id){
  //   payload.country_id = formData?.country_id;
  // }
  // if(formData?.state_id){
  //   payload.state_id = formData?.state_id;
  // }
  // if(formData?.country){
  //   payload.country = formData?.country;
  // }
  // if(formData?.state){
  //   payload.state = formData?.state;
  // }
  // if(formData?.city){
  //   payload.city = formData?.city;
  // }
  // if(formData?.zip_code){
  //   payload.zip_code = formData?.zip_code;
  // }
  // if(formData?.address){
  //   payload.address = formData?.address;
  // }
  if (formData?.term_condition) {
    payload.term_condition = formData?.term_condition;
  }
  if (formData?.inquiry_products) {
    formData?.inquiry_products?.map((formData) => {
      const payloadData = {
        // product_id: "",
        product_name: "",
        product_qty: null,
        product_unit: "",
        product_unit_id: "",
        // product_grade: "",
        // product_grade_id: "",
        id: "",
      };
      if (formData?.id) {
        payloadData.id = formData?.id;
      } else {
        delete payloadData?.id;
      }
      // if(formData?.product_id){
      //   payloadData.product_id = formData?.product_id;
      // }
      if (formData?.product_name) {
        payloadData.product_name = formData?.product_name;
      }
      if (formData?.product_qty) {
        payloadData.product_qty = parseInt(formData?.product_qty);
      }
      if (formData?.product_unit) {
        payloadData.product_unit = formData?.product_unit;
      }
      if (formData?.product_unit_id) {
        payloadData.product_unit_id = formData?.product_unit_id;
      }
      // if(formData?.product_grade){
      //   payloadData.product_grade = formData?.product_grade;
      // }
      // if(formData?.product_grade_id){
      //   payloadData.product_grade_id = formData?.product_grade_id;
      // }
      payload.inquiry_products.push(payloadData);
    });
  }

  return payload;
};

export function objectCompare(obj1, obj2) {
  //Loop through properties in object 1
  for (let p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (!objectCompare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case "function":
        if (
          typeof obj2[p] == "undefined" ||
          (p != "compare" && obj1[p].toString() != obj2[p].toString())
        )
          return false;
        break;
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (let p in obj2) {
    if (typeof obj1[p] == "undefined") return false;
  }
  return true;
}

export function formatDateToYYYYMMDD(date) {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const sortTableLayout = (products, request) => {
  if (products?.length > 0) {
    const productsCopy = [...products];
    return productsCopy?.sort((a, b) => {
      let comparison = 0;
      if (a?.[request?.sort_by]?.toLowerCase() > b?.[request?.sort_by]?.toLowerCase()) {
        comparison = 1;
      } else if (a?.[request?.sort_by]?.toLowerCase() < b?.[request?.sort_by]?.toLowerCase()) {
        comparison = -1;
      }

      // Reverse the order if request?.sort_order is 'desc'
      if (request?.sort_order === "desc") {
        comparison *= -1;
      }

      return comparison;
    });
  } else {
    return [];
  }
};


export const downloadInvoice=(data:CombinedDeliveredList,isDownload:boolean,access_token:string,dispatch?:any,setLoader?:any)=>{
  if(setLoader)
  setLoader(data?.invoice_id);
  getData(
    `${App_url.link.ENDPOINT_LINKS.INTUIT_DOWNLOAD}/${data?.invoice_id}`,
    access_token, dispatch,isDownload
  )
  // downloadFile1(`${App_url.link.ENDPOINT_LINKS.INTUIT_DOWNLOAD}/${data?.invoice_id}`,`INVOICE_${data?.invoice_number}_from_KAS_METALS`,access_token)
  .then(() => {
  if(setLoader)
    setLoader("")
  });
}

export function extractTextFromHTML(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
}
export function callPayloadProjects(formData: AddIProjectType){
  const payload:any = {
    project_id: "",
    name: "",
    bill_to_company_name: "",
    ship_to_company_name: "",
    estimated_tonnage: "",
    foreman_name: "",
    foreman_email: "",
    coordinator_name: "",
    coordinator_email: "",
    submission_email_chain: "",
    general_contractor_name: "",
    concrete_contractor: "",
    architect_name: "",
    engineer_name: "",
    customer_id: "",
    project_type_id: "",
    project_scope_id: "",
    scope_of_work_id: "",
    dor: "",
    // doc: "",
    tax_exempt: false,
    customer_name: "",
    email: "",
    billing_address: "",
    shipping_address: "",
    project_type_name: "",
    project_scope_name: "",
    scope_of_work_name: "",
    shipping_pincode: "",
    billing_pincode: "",
    foreman_phone:"",
    foreman_phone_code:"",
    coordinator_phone_code:"",
    coordinator_phone:"",
  }
  if(formData?.project_id){
    payload.project_id = formData?.project_id;
  }
  if(formData?.name){
    payload.name = formData?.name;
  }
  if(formData?.bill_to_company_name){
    payload.bill_to_company_name = formData?.bill_to_company_name;
  }
  if(formData?.ship_to_company_name){
    payload.ship_to_company_name = formData?.ship_to_company_name;
  }
  if(formData?.estimated_tonnage){
    payload.estimated_tonnage = formData?.estimated_tonnage;
  }
  if(formData?.foreman_name){
    payload.foreman_name = formData?.foreman_name;
  }
  if(formData?.foreman_email){
    payload.foreman_email = formData?.foreman_email;
  }
  if(formData?.coordinator_name){
    payload.coordinator_name = formData?.coordinator_name;
  }
  if(formData?.coordinator_email){
    payload.coordinator_email = formData?.coordinator_email;
  }
  if(formData?.submission_email_chain){
    payload.submission_email_chain = formData?.submission_email_chain;
  }
  if(formData?.general_contractor_name){
    payload.general_contractor_name = formData?.general_contractor_name;
  }
  if(formData?.concrete_contractor){
    payload.concrete_contractor = formData?.concrete_contractor;
  }
  if(formData?.architect_name){
    payload.architect_name = formData?.architect_name;
  }
  if(formData?.engineer_name){
    payload.engineer_name = formData?.engineer_name;
  }
  if(formData?.customer_id){
    payload.customer_id = formData?.customer_id;
  }
  if(formData?.project_type_id){
    payload.project_type_id = formData?.project_type_id;
  }
  if(formData?.project_scope_id){
    payload.project_scope_id = formData?.project_scope_id;
  }
  if(formData?.scope_of_work_id){
    payload.scope_of_work_id = formData?.scope_of_work_id;
  }
  if(formData?.dor){
    payload.dor = formatDate2(formData?.dor);
  }
  if(formData?.doc){
    payload.doc = formatDate2(formData?.doc);
  }
  if(formData?.tax_exempt){
    payload.tax_exempt = formData?.tax_exempt;
  }
  if(formData?.customer_name){
    payload.customer_name = formData?.customer_name;
  }
  if(formData?.email){
    payload.email = formData?.email;
  }
  if(formData?.billing_address){
    payload.billing_address = formData?.billing_address;
  }
  if(formData?.shipping_address){
    payload.shipping_address = formData?.shipping_address;
  }
  if(formData?.project_type_name){
    payload.project_type_name = formData?.project_type_name;
  }
  if(formData?.project_scope_name){
    payload.project_scope_name = formData?.project_scope_name;
  }
  if(formData?.scope_of_work_name){
    payload.scope_of_work_name = formData?.scope_of_work_name;
  }
  if(formData?.shipping_pincode){
    payload.shipping_pincode = formData?.shipping_pincode;
  }
  if(formData?.billing_pincode){
    payload.billing_pincode = formData?.billing_pincode;
  }
  if (formData?.shippingAddress?.address) {
    payload.shipping_address = formData?.shippingAddress?.address;
  }
  if (formData?.billingAddress?.address) {
    payload.billing_address = formData?.billingAddress?.address;
  }
  if(formData?.coordinator_phone?.phone){
    payload.coordinator_phone = formData?.coordinator_phone?.phone;
  }
  if(formData?.coordinator_phone?.phone_code){
    payload.coordinator_phone_code = formData?.coordinator_phone?.phone_code;
  }
  if(formData?.foreman_phone?.phone_code){
    payload.foreman_phone_code = formData?.foreman_phone?.phone_code;
  }
  if(formData?.foreman_phone?.phone){
    payload.foreman_phone = formData?.foreman_phone?.phone;
  }
  return payload;
}