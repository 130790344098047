import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import posterReducer from "./reducers/posterReducer";
import uiReducer from "./reducers/uiReducer";
import {
  externalUserReducer,
  IExernalUserTypesRes,
} from "./modules/externalUser";
import { IRoleRes, rolesReducers } from "./modules/roles";
import { IInternalUserTypesRes, internalUserReducers } from "./modules/internal_user";
import { ILocalityRes, localityReducers } from "./modules/allMasters/locality";
import { countriesReducers } from "./modules/countries";
import {
  IProjectRes,
  projectTypeReducers,
} from "./modules/allMasters/project_type";
import { IProductRes, productReducers } from "./modules/allMasters/products";
import {
  ISupplierTypesRes,
  supplierTypeReducers,
} from "./modules/allMasters/supplier_type";
import { ISupplierRes, supplierReducers } from "./modules/allMasters/supplier";
import {
  ISizeSpecsRes,
  sizeSpecsReducers,
} from "./modules/allMasters/sizeSpecs";
import {
  IScopeOfWorkRes,
  scopeOfWorkReducers,
} from "./modules/allMasters/scope_of_work";
import { gradeReducers, IGradeRes } from "./modules/allMasters/grade";
import { coatingReducers, ICoatingRes } from "./modules/allMasters/coating";
import { ITaxRes, taxReducers } from "./modules/allMasters/tax";

import { scopeOfProjectReducers } from "./modules/allMasters/scope_of_project";
import { userDataReducers } from "./modules/user_data";
import statusOfDrawingReducers from "./modules/allMasters/status_of_drawing/reducer";
import { distributionReducers, IDistributionTypes } from "./modules/distribution";
import emailSetupReducers from "./modules/allMasters/sender_setup/reducer";
import emailTemplateReducers from "./modules/emailTemplate/reducer";
import credentialReducers from "./modules/allMasters/api_credentials/reducer";
import customersReducers from "./modules/customers/reducer";
import deliveryByServiceReducers from "./modules/allMasters/deliveryByService/reducer";
import inventoryReducers from "./modules/inventory/reducer";
import fabricationReducers from "./modules/fabrication/reducer";
import productLengthReducers from "./modules/allMasters/productLength/reducer";
import { ChecklistReducers, IChecklistRes } from "./modules/checklist";
import projectsReducers from "./modules/projectManagement/reducer";
import stockSupplyReducers from "./modules/order_supply/reducer";


// import { orderStockReducers } from "./modules/order_supply";
import productUnitServiceReducers from "./modules/allMasters/unit/reducer";
import { IUnitServiceRes } from "./modules/allMasters/unit";
import { proposalReducers } from "./modules/proposal";
import { InitialStateType } from "../utils/types";
import { IProductLengthRes } from "./modules/allMasters/productLength";
import { IStatusOfDrawingRes } from "./modules/allMasters/status_of_drawing";
import { IUserRes } from "./modules/user_data/types";
import dashboardReducers from "./modules/dashboard/reducer";
import { logisticReducer } from "./modules/logistic/index";
import logisticLocationServiceReducers from "./modules/allMasters/logisticLocationService/reducer";

export interface ApplicationState {
  posterReducers: InitialStateType;
  roles: IRoleRes;
  external_user: IExernalUserTypesRes;
  internal_user: IInternalUserTypesRes;
  locality_list: ILocalityRes;
  project_type: IProjectRes;
  products: IProductRes;
  supplier_type: ISupplierTypesRes;
  supplier: ISupplierRes;
  size_spec: ISizeSpecsRes;
  scope_of_work: IScopeOfWorkRes;
  grade: IGradeRes;
  coating: ICoatingRes;
  tax: ITaxRes;
  status_of_drawing: IStatusOfDrawingRes;
  user_data: IUserRes;
  distributionReducers:IDistributionTypes;
  productLength: IProductLengthRes;
  productUnitService: IUnitServiceRes;
  checklist:IChecklistRes;
  projects:IProjectRes;
 
}

const combinedReducer = combineReducers({
  roles: rolesReducers,
  external_user: externalUserReducer,
  internal_user: internalUserReducers,
  locality_list: localityReducers,
  countries: countriesReducers,
  project_type: projectTypeReducers,
  scope_of_project: scopeOfProjectReducers,
  products: productReducers,
  supplier_type: supplierTypeReducers,
  supplier: supplierReducers,
  size_spec: sizeSpecsReducers,
  scope_of_work: scopeOfWorkReducers,
  grade: gradeReducers,
  coating: coatingReducers,
  productLength: productLengthReducers,
  productUnitService: productUnitServiceReducers,
  tax: taxReducers,
  status_of_drawing: statusOfDrawingReducers,
  user_data: userDataReducers,
  distributionReducers:distributionReducers,
  stockSupplyReducers:stockSupplyReducers,
  emailSetupReducers:emailSetupReducers,
  emailTemplateReducers:emailTemplateReducers,
  credentialReducers:credentialReducers,
  customersReducers:customersReducers,
  deliveryByServiceReducers: deliveryByServiceReducers,
  inventory_list:inventoryReducers,
  fabricationReducers:fabricationReducers,
  checkist:ChecklistReducers,
  projectsReducers:projectsReducers,
  proposalReducers:proposalReducers,
  logisticReducer:logisticReducer,
  logisticLocationServiceReducers:logisticLocationServiceReducers,
  dashboardReducers:dashboardReducers,
  posterReducers: posterReducer,
  uiReducer: uiReducer,
});

const rootReducer = combineReducers({
  combinedReducer: persistReducer(
    { key: "chathub-store", storage, blacklist: ["uiReducer"] },
    combinedReducer
  ),
});

export function makeStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST"], // Ignore serializability check for specific actions
        },
      }),
  });

  const persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = makeStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
