/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from "./action";
import { IDistributionTypes, IInquiryTypesRes } from "./types";

const initialState: IDistributionTypes = {
  request_quotation: {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  get_request_quotation:null,
  supplierList:  {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  inquirySupplierList:  {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  inquiryDetails: null,
  inquirySaleDetails: null,
  inquirySupplierSale: null,
  sale_order:  {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  purchase_order: {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  scheduledList: {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  delivered_list: {
    items: [],
    totalCount: 0,
    optionList: [],
  },
  detail_inquiry:null,
  email_details:null,
  inquiryItemDetails:null,
  inquirySaleData:null,
  intuit_list: {
    Item: [],
    startPosition: 0,
    maxResults: 0,
    optionList:[]
  },
  tax_list: {
    maxResults: 0,
    startPosition: 0,
    TaxRate:[],
    totalCount: 0,
    optionList: [],
  },
  getTaxRate: null,
  terms_list:{
    optionList: [],
    maxResults: 0,
    startPosition: 0,
    Term:[],
  }

};

const distributionReducers = (
  state: IDistributionTypes = initialState,
  action
): IDistributionTypes => {
  switch (action.type) {
    case ActionTypes.REQUEST_QUOTATION_LIST: {
      return {
        ...state,
        request_quotation: {
          ...state?.request_quotation,
          ...action.payload,
        },
      };
    }
    case ActionTypes.PO_LIST: {
      return {
        ...state,
        purchase_order: {
          ...state?.purchase_order,
          ...action.payload,
        },
      };
    }
    case ActionTypes.SET_STORE_SCHEDULED_LIST: {
      return {
        ...state,
        scheduledList: {
          ...state?.scheduledList,
          ...action.payload,
        },
      };
    }
    case ActionTypes.DELIVERED_LIST: {
     const itemList = action?.payload?.items?.map((item, index)=>{
        const invoice = JSON.parse(item?.invoice)
        return{
          ...item,
          invoice: invoice
        }
      })
      return {
        ...state,
        delivered_list: {
          ...action.payload,
          items: itemList,
        },
      };
    }
    case ActionTypes.ADD_REQUEST_QUOTATION: {
      const items = [];
      items.push(action?.payload);
      state?.request_quotation?.items?.map((item, index) => {
        items.push(item);
      });
      const totalCount = state?.request_quotation?.totalCount + 1;
     
      return {
        ...state,
        request_quotation: {
          ...state?.request_quotation,
          items: items,
          totalCount: totalCount,
        },
      };
    }
    case ActionTypes.UPDATE_REQUEST_QUOTATION: {
      const updatedList = state?.request_quotation?.items.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

      return {
        ...state,
        request_quotation: {
          ...state?.request_quotation,
          items: updatedList,
        },
      };
    }
    case ActionTypes.DELETE_REQUEST_QUOTATION: {
      const updatedList = state?.request_quotation.items.filter(
        (item) => item.id !== action.payload.id
      );
      const totalCount = state?.request_quotation?.totalCount - 1;
      return {
        ...state,
        request_quotation: {
          ...state?.request_quotation,
          items: updatedList,
          totalCount: totalCount,
        },
      };
    }
    case ActionTypes.GET_REQUEST_QUOTATION: {
      
      return {
        ...state,
        get_request_quotation: action.payload
      }
    }
    case ActionTypes.DETAIL_INQUIRY: {
      
      return {
        ...state,
        detail_inquiry: action.payload
      }
    }
    case ActionTypes.SET_STORE_SUPPLIER_LIST: {
      return {
        ...state,
        supplierList: {
          ...state?.supplierList,
          ...action.payload,
        },
      };
    }
    case ActionTypes.SET_STORE_INQUIRY_SUPPLIER_LIST: {
      return {
        ...state,
        inquirySupplierList: {
          ...action.payload,
        },
      };
    }
    case ActionTypes.SET_STORE_INQUIRY_DETAILS: {
      return {
        ...state,
        inquiryDetails: action.payload?action.payload:null,
      }
    }
    case ActionTypes.SALE_ORDER_LIST: {
      return {
        ...state,
        sale_order: {
          ...state?.sale_order,
          ...action.payload,
        },
      };
    }
    case ActionTypes.ADD_SALE_ORDER_LIST: {
      const items = [];
      items.push(action?.payload);
      state?.sale_order?.items?.map((item, index) => {
        items.push(item);
      });
      const totalCount = state?.sale_order?.totalCount + 1;
     
      return {
        ...state,
        sale_order: {
          items: items,
          totalCount: totalCount,
        },
      };
    }
    case ActionTypes.CHANGE_SALE_ORDER_STATUS: {
      const updatedList = state?.sale_order.items.map(
        (item) => item.id === action.payload?.id?{...item,status: action?.payload?.status}:item
      );
      return {
        ...state,
        sale_order: {
          items: updatedList,
        },
      };
    }
    case ActionTypes.DELETE_SALE_ORDER: {
      const updatedList = state?.sale_order?.items.filter(
        (item) => item.id !== action.payload
      );     
      const totalCount = state?.sale_order?.totalCount - 1;
      return {
        ...state,
        sale_order: {
          items: updatedList,
          totalCount: totalCount,
        },
      }
    }
    case ActionTypes.DELETE_SCHEDULED_DELIVERY: {
      console.log("DELETE_SCHEDULED_DELIVERY::",action.payload);
      
      const updatedList = state?.purchase_order?.items.filter(
        (item) => item.id !== action.payload
      );     
      console.log("updatedList::",action.payload);
      const totalCount = state?.purchase_order?.totalCount - 1;
      return {
        ...state,
        purchase_order: {
          items: updatedList,
          totalCount: totalCount,
        },
      }
    }
    case ActionTypes.DELETE_BOL: {
      const updatedList = state?.scheduledList?.items.filter(
        (item) => item.id !== action.payload
      );     
      const totalCount = state?.scheduledList?.totalCount - 1;

      console.log("ACTION PAYLOAD ",action.payload);
      
      return {
        ...state,
        scheduledList: {
          items: updatedList,
          totalCount: totalCount,
        },
      }
    }
    case ActionTypes.ADD_PADLET: {
      const updated_list = state?.scheduledList?.items?.map(item =>
        item.id === action?.payload?.id ? action?.payload : item
      );
      return {
        ...state,
        scheduledList:{
          items: updated_list,
        }
      };
    }
    case ActionTypes.SET_STORE_SALE_INQUIRY_DETAILS: {
      return {
        ...state,
        inquirySaleDetails: action.payload?action.payload:null,
      };
    }
    case ActionTypes.SET_STORE_INQUIRY_SALE_DATA: {
      return {
        ...state,
        inquirySaleData: action.payload?action.payload:null,
      };
    }
    case ActionTypes.SET_STORE_INQUIRY_SUPPLIER_SALE: {
      const data = {
        ...(action.payload?action.payload:null)
      };
      if(action.payload && data.inquirySupplier){
        const ProductList = JSON.parse(action?.payload?.inquirySupplier?.product_price);
        const productPriceModify = ProductList?.map((item, index)=>({
          ...item,
          product_buy_price: item?.price
        }))
        data.inquirySupplier = {
          ...data.inquirySupplier,
          inquiry_products: productPriceModify,
        };
      };
      return {
        ...state,
        inquirySupplierSale: action.payload?data:null,
      };
    }
    case ActionTypes.EMAIL_DETAIL: {
      return {
       ...state,
        email_details: action.payload??null,
      }
    }
    case ActionTypes.SET_INTUIT_LIST: {
      const optionList = action?.payload?.Item?.map((item: any, index: any)=>{
        return{
          value: item.Id,
          label: item.Name,
        }
      })
      return {
        ...state,
        intuit_list: {
          ...action.payload,
          optionList: optionList,
        },
      };
    }
    case ActionTypes.SET_STORE_INQUIRY_ITEM_DETAILS: {
      return {
        ...state,
        inquiryItemDetails: action.payload?action.payload:null,
      };
    }

    case ActionTypes.SET_TAX_LIST: {
      const optionList = action?.payload?.TaxRate?.map((item: any, index: any)=>{
        return{
          value: item.Id,
          label: item.Name+" "+'('+item.RateValue+')%',
        }
      })
      return {
        ...state,
        tax_list: {
          ...action.payload,
          optionList: optionList,
        },
      };
    }
    case ActionTypes.SET_TERMS_LIST: {
      const optionList = action?.payload?.Term?.map((item: any, index: any)=>{
        return{
          value: item.Id,
          label: item.Name,
        }
      })
      return {
        ...state,
        terms_list: {
          ...action.payload,
          optionList: optionList,
        },
      };
    }
    case ActionTypes?.SET_STORE_TAX_RATE:{
      return{
        ...state,
        getTaxRate: action?.payload ? action?.payload: initialState?.getTaxRate,
      }
    }
    default:
      return state;
  }
};

export default distributionReducers;
