import { IInventoryListRes } from "./types";

export const ActionTypes = {
  SET_STORE_INVENTORY_LIST: "SET_STORE_INVENTORY_LIST",
  SET_STORE_INVENTORY_DETAILS:"SET_STORE_INVENTORY_DETAILS",
  SET_STORE_INVENTORY_STOCK_LIST:"SET_STORE_INVENTORY_STOCK_LIST",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  SET_STORE_UPDATE_INVENTORY_STOCK:"SET_STORE_UPDATE_INVENTORY_STOCK",
};

export const setStoreInventoryList = (payload: IInventoryListRes) => ({
  type: ActionTypes.SET_STORE_INVENTORY_LIST,
  payload,
});
export const setStoreInventoryStockList = (payload: IInventoryListRes) => ({
  type: ActionTypes.SET_STORE_INVENTORY_STOCK_LIST,
  payload,
});
export const setStoreInventoryDetails = (payload: IInventoryListRes) => ({
  type: ActionTypes.SET_STORE_INVENTORY_DETAILS,
  payload,
});
export const setStoreUpdateInventoryStock = (payload: IInventoryListRes) => ({
  type: ActionTypes.SET_STORE_UPDATE_INVENTORY_STOCK,
  payload,
});