/* eslint-disable eqeqeq */

import { ActionTypes } from './action';
import { IInventoryRes } from "./types";


const initialState: IInventoryRes = {
  inventoryList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  inventoryStockList:{
    items: [],
    totalCount: 0,
    optionList: [],
  }
};

const inventoryReducers = (state: IInventoryRes = initialState, action): IInventoryRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_INVENTORY_LIST:{
      const optionsList = action?.payload?.parsedItems?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        inventoryList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_INVENTORY_STOCK_LIST:{
      const optionsList = action?.payload?.parsedItems?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        inventoryStockList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_UPDATE_INVENTORY_STOCK:{
      const parsedItems = state?.inventoryStockList?.items?.map((item, index)=>{
        if(item?.id == action?.payload?.id){
          return action?.payload;
        }else{
          return item
        }
      });
      return {
        ...state,
        inventoryStockList:{
          ...state?.inventoryStockList,
          items: parsedItems
        }
      };
    }
    default:
      return state;
  }
};

export default inventoryReducers;
