export const customerServicePermission = [
    { name: "create", label: "Create", api_list: "customerService:create,customerService:list,customerService:get", value: false },
    { name: "update", label: "Update", api_list: "customerService:update,customerService:updateStatus,customerService:list,customerService:get", value: false },
    { name: "delete", label: "Delete", api_list: "customerService:delete,customerService:list", value: false },
    { name: "list", label: "View Only", api_list: "customerService:list,customerService:get", value: false },
];
export const supplierServicePermission = [
    { name: "create", label: "Create", api_list: "supplierService:create,supplierService:list,supplierService:get", value: false },
    { name: "update", label: "Update", api_list: "supplierService:update,supplierService:updateStatus,supplierService:list,supplierService:get", value: false },
    { name: "delete", label: "Delete", api_list: "supplierService:delete,supplierService:list", value: false },
    { name: "list", label: "View Only", api_list: "supplierService:list,supplierService:get", value: false },
]
export const projectServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "projectService:assign,packageService:create,customerService:list,drawingService:create,drawingService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:create,projectService:list,projectService:get,intuitService:items" },
    { name: "update", label: "Update", value: false, api_list: "packageService:update,packageService:updateStatus,customerService:list,drawingService:update,drawingService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:update,projectService:updateStatus,projectService:updatePricing,projectService:list,projectService:get,intuitService:items" },
    { name: "delete", label: "Delete", value: false, api_list: "packageService:delete,projectService:delete,drawingService:create,drawingService:list,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:list" },
    { name: "list", label: "View Only", value: false, api_list: "packageService:list,projectService:list,projectService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list" },
]
export const inquiryServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "inquirySaleService:rollback,emailTemplateService:get,inquiryService:sale,inquirySaleService:pdf,inquirySaleService:getTaxRate,deliveryByService:list,taxService:list,productUnitService:list,inquirySaleService:create,inquiryService:supplier_list,customerService:list,customerService:get,gradeService:list,inquirySaleService:list,inquirySaleService:get,inquirySaleService:po,inquiryService:create,inquiryService:list,inquiryService:get,inquirySaleService:get,intuitService:terms" },
    { name: "update", label: "Update", value: false, api_list: "inquirySaleService:rollback,emailTemplateService:get,inquiryService:recover,inquirySaleService:pdf,inquirySaleService:getTaxRate,inquirySaleService:update,deliveryByService:list,inquirySaleService:recover,inquiryService:sale,taxService:list,productUnitService:list,inquiryService:supplier_list,inquiryService:supplier,inquiryService:supplier_price,inquiryService:supplier_sale,customerService:get,customerService:list,customerService:get,gradeService:list,inquirySaleService:list,inquirySaleService:get,inquirySaleService:accept,inquirySaleService:po,inquirySaleService:schedule,inquirySaleService:deliver,inquirySaleService:schedulePadlet,inquiryService:update,inquiryService:updateStatus,inquiryService:list,inquiryService:get,inquirySaleService:get,intuitService:terms" },
    { name: "delete", label: "Delete", value: false, api_list: "inquiryService:archive,customerService:list,gradeService:list,inquirySaleService:list,inquiryService:delete,inquirySaleService:reject,inquirySaleService:archive,inquiryService:list" },
    { name: "list", label: "View Only", value: false, api_list: "inquiryService:list,inquiryService:supplier_list,inquiryService:get,inquirySaleService:list,inquirySaleService:get" },
]
export const fabricationServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "fabricationService:create,customerService:list,gradeService:list,locationFacilityService:list,productLengthService:list,inventoryService:get,supplierService:list,projectService:list,projectService:get,supplierTypeService:list,fabricationScheduleService:schedule,fabricationScheduleService:schedulePadlet,fabricationScheduleService:deliver,fabricationService:list,fabricationScheduleService:schedule,fabricationScheduleService:schedulePadlet,fabricationService:get,fabricationScheduleService:get,coatingService:list,sizeSpecificationService:list,intuitService:terms" },
    { name: "update", label: "Update", value: false, api_list: "fabricationService:update,inventoryService:get,customerService:list,locationFacilityService:list,gradeService:list,supplierService:list,projectService:list,supplierTypeService:list,projectService:get,fabricationService:updateStatus,fabricationService:list,fabricationScheduleService:schedule,fabricationScheduleService:schedulePadlet,fabricationScheduleService:deliver,fabricationService:get,fabricationScheduleService:get,intuitService:terms" },
    { name: "delete", label: "Delete", value: false, api_list: "fabricationService:delete,fabricationService:list" },
    { name: "list", label: "View Only", value: false, api_list: "fabricationService:list,fabricationService:get,customerService:list,gradeService:list,supplierService:list,projectService:list,supplierTypeService:list" },
]
export const stockServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "stockService:create,intuitService:taxRate,locationFacilityService:list,intuitService:terms,stockService:list,stockService:get,stockScheduleService:schedule,projectService:get,stockService:get,stockScheduleService:get,stockService:get,stockScheduleService:deliver" },
    { name: "update", label: "Update", value: false, api_list: "stockService:update,intuitService:taxRate,locationFacilityService:list,intuitService:terms,stockService:updateStatus,stockService:list,stockService:get,projectService:get,stockService:get,stockScheduleService:get,stockService:get,stockScheduleService:deliver" },
    { name: "delete", label: "Delete", value: false, api_list: "stockService:delete,stockService:list" },
    { name: "list", label: "View Only", value: false, api_list: "stockService:list,stockService:get" },
]
export const logisticServicePermission = [
    { name: "create", label: "Create", value: false, },
    { name: "update", label: "Update", value: false, },
    { name: "delete", label: "Delete", value: false, },
    { name: "list", label: "View Only", value: false, api_list: "logisticService:list,logisticService:get,deliveryByService:list,logisticService:location" },
]
export const inventoryServicePermission = [
    { name: "create", label: "Create", value: false, api_list: "productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:create,productLengthService:list,inventoryService:get" },
    { name: "update", label: "Update", value: false, api_list: "productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:update,inventoryService:updateStatus,inventoryService:get" },
    { name: "delete", label: "Delete", value: false, api_list: "productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:delete" },
    { name: "list", label: "View Only", value: false, api_list: "productService:list,locationFacilityService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:list,inventoryService:stock,inventoryService:get" },
]
export const proposalServicePermission = [
    { name: "create", label: "Create", api_list: "proposalService:create,proposalService:list,proposalService:get,proposalService:sent,proposalService:accept,proposalService:upload", value: false },
    { name: "update", label: "Update", api_list: "proposalService:recover,proposalService:update,proposalService:updateStatus,proposalService:list,proposalService:get,proposalService:sent", value: false },
    { name: "delete", label: "Delete", api_list: "proposalService:delete,proposalService:list,proposalService:reject", value: false },
    { name: "list", label: "View Only", api_list: "proposalService:list,proposalService:get,scopeOfWorkService:list,proposalService:archive", value: false },
]
export const projectManagementPermission = [
    { name: "create", label: "Create", value: false, api_list: "drawingVersionHistoryService:create,drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:create,drawingVersionService:list,drawingVersionService:get,revisionHistoryService:create,changeOrderService:create,revisionHistoryService:list,revisionHistoryService:get,listLogService:create,listLogService:get,locationFacilityService:list,packageService:get,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:create,productLengthService:list,inventoryService:get" },
    { name: "update", label: "Update", value: false, api_list: "drawingVersionHistoryService:update,drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:update,drawingVersionService:list,drawingVersionService:get,revisionHistoryService:update,changeOrderService:update,revisionHistoryService:list,revisionHistoryService:get,listLogService:update,changeOrderService:updateStatus,listLogService:updateStatus,locationFacilityService:list,packageService:get,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:update,inventoryService:updateStatus,inventoryService:get" },
    { name: "delete", label: "Delete", value: false, api_list: "drawingVersionHistoryService:delete,drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:delete,drawingVersionService:list,drawingVersionService:get,changeOrderService:delete,revisionHistoryService:delete,revisionHistoryService:list,revisionHistoryService:get,listLogService:delete,locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:delete,drawingService:delete" },
    { name: "list", label: "View Only", value: false, api_list: "drawingVersionHistoryService:list,drawingVersionHistoryService:get,drawingVersionService:list,drawingVersionService:get,changeOrderService:list,revisionHistoryService:list,revisionHistoryService:get,listLogService:list,locationFacilityService:list,productService:list,packageService:get,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:list,inventoryService:stock,inventoryService:get,userService:list" },
]
export const dashboardPermission = [
    { name: "create", label: "Create", value: false, api_list: "frontDashboardService:get" },
    // { name: "update", label: "Update", value: false, api_list: "locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:update,inventoryService:updateStatus,inventoryService:get" },
    // { name: "delete", label: "Delete", value: false, api_list: "locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:delete" },
    // { name: "list", label: "View Only", value: false, api_list: "locationFacilityService:list,productService:list,gradeService:list,coatingService:list,sizeSpecificationService:list,supplierService:list,supplierTypeService:list,productLengthService:list,inventoryService:list,inventoryService:stock,inventoryService:get" },
]
export const FrontOfficeRolePermissions = [
    {
        name: "Dashboard",
        type: "frontDashboardService",
        permissions: dashboardPermission,
        user_type: "front-office",
    },
    {
        name: "Customers",
        type: "customerService",
        permissions: customerServicePermission,
        user_type: "front-office",
    },
    {
        name: "Supplier",
        type: "supplierService",
        permissions: supplierServicePermission,
        user_type: "front-office",
    },
    {
        name: "Projects",
        type: "projectService",
        permissions: projectServicePermission,
        user_type: "front-office",
    },
    {
        name: "Distribution",
        type: "inquiryService",
        permissions: inquiryServicePermission,
        user_type: "front-office",
    },
    {
        name: "Fabrication Supply",
        type: "fabricationService",
        permissions: fabricationServicePermission,
        user_type: "front-office",
    },
    {
        name: "Stock Supply",
        type: "stockService",
        permissions: stockServicePermission,
        user_type: "front-office",
    },
    {
        name: "Logistic",
        type: "logisticService",
        permissions: logisticServicePermission,
        user_type: "front-office",
    },
    {
        name: "Inventory",
        type: "inventoryService",
        permissions: inventoryServicePermission,
        user_type: "front-office",
    },
    {
        name: "Proposal",
        type: "proposalService",
        permissions: proposalServicePermission,
        user_type: "front-office",
    },
    {
        name: "Project Management",
        type: "projectService",
        permissions: projectManagementPermission,
        user_type: "front-office",
    }
];

export const BackOfficeRolePermission = [
    {
        name: "Customers",
        type: "customerService",
        permissions: [
            { name: "create", label: "Create", api_list: "customerService:create,customerService:list,customerService:get", value: false },
            { name: "update", label: "Update", api_list: "customerService:update,customerService:updateStatus,customerService:list,customerService:get", value: false },
            { name: "delete", label: "Delete", api_list: "customerService:delete,customerService:list", value: false },
            { name: "list", label: "View Only", api_list: "customerService:list,customerService:get", value: false },
        ],
        status: false,
    },
    {
        name: "Projects",
        type: "projectService",
        permissions: [
            { name: "create", label: "Create", value: false, api_list: "customerService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:create,projectService:list,projectService:get," },
            { name: "update", label: "Update", value: false, api_list: "customerService:list,customerService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:update,projectService:updateStatus,projectService:updatePricing,projectService:list,projectService:get" },
            { name: "delete", label: "Delete", value: false, api_list: "projectService:delete,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list,projectService:list" },
            { name: "list", label: "View Only", value: false, api_list: "projectService:list,projectService:get,scopeOfWorkService:list,projectTypeService:list,projectScopeService:list" },
        ],
        status: false,
    },

]