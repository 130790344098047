/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from './action';
import { IProductRes } from "./types";


const initialState: IProductRes = {
    items: [],
    totalCount: 0,
    optionList:[]
};

const productReducers = (state: IProductRes = initialState, action): IProductRes => {
  switch (action?.type) {
    case ActionTypes.PRODUCT_LIST:{
      const optionList = action?.payload?.items?.map((item: any, index: any)=>{
        return{
          ...item,
          value: item.id,
          label: item.name,
        }
      })
      return {
        ...state,
        ...action?.payload,
        optionList: optionList
      };
    }
    case ActionTypes.ADD_PRODUCT:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_PRODUCT:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_PRODUCT:{
      const updatedList = state.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
    case ActionTypes.ADMIN_LOGOUT:{
      return initialState
    }
    default:
      return state;
  }
};

export default productReducers;
